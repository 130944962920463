@import "partials/gmri";
@import "partials/vars";

$primary: $citizen-science;
$enable-rounded: false;

$tan-background: #f2f1ee;
$dark-background: #dddbd5;

@import "~bootstrap/scss/bootstrap.scss";

$primary-nav-padding: $spacer * 6;
// Point when header usually wraps and the menu button will get bumped to a new line
$primary-nav-padding-xs: $spacer * 10;

$big-border-radius: 50rem;

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $citizen-science;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.App {
  & > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .primary-nav {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.placeholder-header {
  background-color: $citizen-science;
  min-height: $primary-nav-padding;

  @include media-breakpoint-down(sm) {
    min-height: $primary-nav-padding-xs;
  }
}

.block-anchor {
  margin-bottom: $spacer * 2;
  text-transform: uppercase;
}

.header-block {
  .btn {
    background: none;
    color: white;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    border-radius: $big-border-radius;
    margin-top: $spacer * 2;
    padding: $spacer $spacer * 2;
  }
}

.header-block,
.featured-project-block {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
  h3,
  h4,
  h5,
  h6 {
    font-weight: 100;
  }
}

.featured-project-block,
.paragraph-image-full {
  .btn {
    background-color: white;
    color: $gmri-blue;
    border-style: none;
    border-radius: $big-border-radius;
    margin-top: $spacer * 2;
    padding: $spacer $spacer * 2;
  }
}

.paragraph-image-block,
.paragraph-block,
.paragraph-callout-block {
  .btn {
    background-color: white;
    color: $gmri-blue;
    border-style: solid;
    border-color: $gmri-blue;
    border-width: 1px;
    border-radius: $big-border-radius;
    margin-top: $spacer * 2;
    padding: $spacer $spacer * 2;
  }
}

.selected-projects-block,
.paragraph-block,
.paragraph-image-block,
.paragraph-callout-block,
.filtered-projects-block,
.featured-project-block,
.steps-block {
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 2;
}

.quote-block {
  text-align: center;

  $quote-space: $spacer * 3;

  padding-top: $quote-space;
  padding-bottom: $quote-space;

  a:hover {
    text-decoration: none;
  }

  .quote-em {
    padding-bottom: $spacer;
  }

  blockquote {
    font-size: $h3-font-size;
    color: black;
  }

  p {
    color: $gmri-dark-grey;
  }
}

.paragraph-callout-block {
  blockquote {
    color: $gmri-blue;
    font-size: $h3-font-size;

    &:before,
    &:after {
      opacity: 0.5;
    }

    &:before {
      content: "\201C";
      margin-right: 4px;
      margin-left: -8px;
    }
    &:after {
      content: "\201D";
      margin-left: 4px;
      margin-right: -8px;
    }
  }
}

.selected-projects-block,
.paragraph-block,
.paragraph-image-block,
.paragraph-callout-block,
.filtered-projects-block {
  color: black;

  a {
    color: $gmri-blue;
  }

  h3 {
    margin-bottom: $spacer * 2;
  }
}

.selected-projects-block,
.filtered-projects-block {
  .btn {
    background-color: $gmri-blue;
    color: white;
    border-style: none;
    border-radius: $big-border-radius;
    margin-top: $spacer * 2;
    padding: $spacer $spacer * 2;
  }
}

.paragraph-image-full {
  .text-col {
    color: white;
    padding: $spacer * 3;
  }

  .img-col {
    padding-right: 0;
    padding-left: 0;
  }
}

.accordion-block {
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;

  .card {
    border: none;
    border: none;

    .card-header {
      font-weight: 400;
      color: black;
      background: none;
      border-bottom: none;
      border-top: 1px solid $gmri-light-grey;
    }

    :first-child {
      border-top: none;
    }
  }
}

.with-sidebar-block {
  .sidebar {
    padding-right: 0;
    padding-left: 0;

    .sidebar-contents {
      padding: $spacer;
    }

    .paragraph-block {
      color: white;
    }
  }

  .with-sidebar-row {
    margin-top: $spacer * 3;
    margin-bottom: $spacer * 3;
  }
}

.invert-paragraph {
  p {
    color: $dark;
  }
}

.partners-block {
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;

  .partner {
    vertical-align: middle;
    padding: $spacer;
    max-height: 200px;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  h3 {
    margin-bottom: $spacer * 3;
    color: $gmri-dark-grey;
  }
}

.owners-block {
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;

  background-color: $tan-background;

  a {
    color: $gmri-blue;
  }

  h3 {
    margin-bottom: $spacer * 3;
    color: $gmri-dark-grey;
  }

  p {
    margin-bottom: 2px;
  }

  img {
    border-radius: 50%;
  }

  .owner {
    margin-bottom: $spacer;
  }
}

.mission-list {
  .mission-col {
    margin-bottom: $spacer * 2;

    h5 {
      color: black;
    }

    .overlapping-badge {
      position: relative;
      margin-bottom: $spacer;
      min-height: 100px;

      img {
        padding-right: $spacer;
      }

      .mission-badge {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: smaller;
        height: 85px;
        width: 100px;
      }
    }
  }
}

.steps-block {
  background-color: $gmri-light-grey;

  .block-step {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    color: $gmri-dark-grey;

    h6 {
      color: black;
    }

    .step-bar {
      width: 50%;
      height: 1px;
      background-color: black;
      margin-bottom: $spacer;
    }

    a {
      color: $gmri-dark-grey;
    }

    img {
      border-radius: 150px;
      border-style: solid;
      border-width: 2px;
      max-width: 100%;
      padding: 5px;
    }

    &:nth-child(4n + 1) {
      img {
        border-color: $citizen-science;
      }
    }

    &:nth-child(4n + 2) {
      img {
        border-color: $gmri-blue;
      }
    }

    &:nth-child(4n + 3) {
      img {
        border-color: $gmri-accent-green;
      }
    }

    &:nth-child(4n + 4) {
      img {
        border-color: $gmri-orange;
      }
    }

    @include media-breakpoint-down(sm) {
      .step-details {
        padding-top: $spacer;
      }
    }
  }
}

.header-block {
  padding-top: $primary-nav-padding;
  padding-bottom: $spacer * 2;

  @include media-breakpoint-down(sm) {
    padding-top: $primary-nav-padding-xs;
  }
}

.mission-header {
  margin-bottom: 0;

  &.jumbotron {
    background-color: $tan-background;
    padding-bottom: 0;
    padding-top: 0;
    &:before {
      height: $primary-nav-padding;
      background-color: $citizen-science;
      content: "";
      display: block;

      @include media-breakpoint-down(sm) {
        height: $primary-nav-padding-xs;
      }
    }
  }

  .mission-info {
    background-color: $tan-background;

    padding: $spacer * 3 2rem 0 2rem;

    .partners-season {
      border-top: 0.5px solid $gray-600;

      ul {
        list-style-type: none;
        padding: 0;
      }

      .col {
        padding-top: 1rem;
        border-right: 0.5px solid $gray-600;
      }
      .col:last-of-type {
        border-right: none;
      }
    }
  }

  h6 {
    color: $dark;
    font-style: normal;
  }
}

.mission-individual {
  h1,
  h2 {
    padding-left: 1rem;
  }
  .mission-nav {
    color: $dark;

    .nav-item {
      background-color: $dark-background;
      a {
        color: $dark;
        padding: 1rem;
      }

      .active {
        background-color: white;
        color: black;
      }
    }
  }

  .description {
  }

  .protocol {
  }

  .contribute {
    .btn {
      background-color: $gmri-blue;
      color: white;
      border-style: none;
      border-radius: $big-border-radius;
      margin-top: $spacer * 2;
      padding: $spacer $spacer * 2;
    }
  }

  .discussion {
    .mission-topics {
      margin-top: $spacer * 2;

      .list-group {
        margin-top: $spacer;
        margin-bottom: $spacer;
      }
    }
  }

  .data-contribute {
    label {
      margin-right: 0.5rem;
    }
  }
}

.primary-nav {
  color: white;

  a {
    text-decoration: none;
  }

  .btn-primary {
    color: white;
  }

  #nav-mark {
    color: rgba(255, 255, 255, 0.5);
    margin-top: 1.25rem;
    width: 60px;
    height: 1px;
    border: solid 1px rgba(255, 255, 255, 0.5);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .dropdown-item,
  .dropdown-toggle,
  .nav-link {
    font-weight: 900;
    & > a {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .navbar {
    margin-bottom: 0rem;
  }

  .navbar-logo-container {
    display: flex;
    align-items: center;
  }

  @include media-breakpoint-down(md) {
    .navbar-collapse {
      background-color: $citizen-science;
      padding: 1rem;
    }
  }
}

.page-footer {
  padding: $spacer * 3;

  background-color: $gmri-light-grey;

  p {
    font-size: 1rem;
  }

  .small-p p {
    text-align: left;
    margin-bottom: 0;
  }

  .social {
    padding-top: $spacer * 3;
  }

  a {
    font-weight: 300;
    color: $dark;
  }

  .gmri-logo-gray {
    margin-bottom: $spacer;
    text-align: left;
  }

  @include media-breakpoint-down(lg) {
    .gmri-logo-gray {
      padding-top: $spacer;
    }
  }
}

#search-elements {
  margin-top: $spacer;
  margin-bottom: $spacer;
}

.search-icon {
  transform: translateY($spacer * 0.25);
}

.project-partners-profiles {
  background-color: $light;
  margin-top: $spacer * 2;
  margin-bottom: -1 * $spacer;
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 2;

  h6 {
    color: $dark;
  }

  .profiles {
    h3 {
      margin-bottom: $spacer;
    }
    p {
      margin-bottom: 0.5 * $spacer;
    }
    hr {
      margin-top: 0.5 * $spacer;
      margin-bottom: 0.5 * $spacer;
    }
  }

  @include media-breakpoint-up(md) {
    .partners {
      border-left: 1px solid $light;
    }
  }
}

.mission-bio {
  .mission-bio-header {
    margin-top: -1 * $spacer;
    padding-top: $spacer;
    padding-bottom: $spacer;
    background: linear-gradient(180deg, $citizen-science 70%, $body-bg 30%);

    @include media-breakpoint-down(sm) {
      background: $citizen-science;
    }

    h1 {
      color: $body-bg;
      display: inline;
      padding-left: $spacer;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    img {
      vertical-align: middle;
    }
  }
}

p {
  a {
    color: $gmri-orange;
  }
}

.mission-who {
  padding-top: $spacer * 3;
}

.mission-badge {
  background-color: white;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85px;
  width: 100px;
  svg {
    margin-top: 1px;
  }
}

.enlarge-btn {
  background-color: #ffffff00;
  border: none;
  padding: 0 5px 0 5px;

  &:hover {
    background-color: #ffffff00;
  }
}

.enlarge-icon {
  color: $gmri-dark-grey;
  &:hover {
    transform: scale(1.2);
  }
}

.enlarged-photo-header {
  h5 {
    color: $gmri-dark-grey;
  }
}

.required-star {
  color: $gmri-red;
  padding-right: $spacer;
  vertical-align: super;
}

.horizontal-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pad-top-bottom {
  padding-top: $spacer;
  padding-bottom: $spacer;
}

.table th,
.table td {
  padding: 0.5rem;
}

.datasheet-validation-cell {
  width: 100%;
  text-align: center;
}

#notValidatedDatasheetIcon {
  margin-left: 5px;
}

.list-group-item {
  color: $gmri-dark-grey;
}

a.list-group-item {
  color: $primary;
}

// Automatically adjust video sizing
iframe[src*="youtube"],
iframe[src*="vimeo"] {
  width: 100%;
  max-width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

.disabled-field {
  opacity: 30%;
}

.checkbox-container {
  border-left: 1px solid #00000050;
  text-align: center;
}

.photo-checkbox[type="checkbox"] {
  width: 25px;
  height: 25px;
  background: #555;
  position: realtive;
  border-radius: 3px;
  justify-self: center;

  &:hover {
    cursor: pointer;
  }
}

.header-containter {
  position: relative;
  width: 100%;
  overflow: scroll;
}

.photos-header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 120px;
}

.photos-header {
  .flood-cat {
    border-left: 1px solid #00000050;
  }
}

//Coastal-Flooding Data Vis

.filter-layout {
  min-width: 370px;
  margin-left: 30px;
  padding: 20px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.data-vis-top {
  display: flex;
  margin-bottom: 10px;
}

@media (max-width: 1000px) {
  .data-vis-top {
    display: flex;
    flex-direction: column;
  }
  .filter-layout {
    margin: 20px 0 0 0;
    max-width: calc(100vw - 25px);
  }
}

/* Result table alt view toggle*/
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b1b1b1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-disabled {
  position: absolute;
  cursor: default;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc67;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before,
.slider-disabled:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $citizen-science;
}

input:focus + .slider {
  box-shadow: 0 0 1px $citizen-science;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round,
.slider-disabled.round {
  border-radius: 20px;
}

.slider.round:before,
.slider-disabled.round:before {
  border-radius: 50%;
}

.row-photo-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.short-list-group {
  padding: 0px 0px 0px 10px;
}

.minor {
  background-color: #ff9000;
}

.major {
  background-color: #a13ad4;
}

.tooltip {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid black;
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  max-width: 300px;
  z-index: 999;
}

.tooltip-inner {
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  padding: 5px 5px 5px 5px;
}

.tooltip.show {
  opacity: 1;
}

.instruction-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgb(200, 200, 200);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: #6b6b76;
  padding: 3px 10px;
}

.legend-mini {
  position: absolute;
  top: 0;
  left: 0;
  background: #d1d1d1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 5px 5px;
  margin: 10px;
  color: #6b6b76;
  outline: none;
  display: flex;
  flex-direction: column;
  width: "120px";
}

.legend {
  position: absolute;
  bottom: 40px;
  right: 0;
  background: #d1d1d1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 5px 5px;
  margin: 10px;
  color: #6b6b76;
  outline: none;
  display: flex;
  flex-direction: column;
  width: "120px";
}

.plotline {
  padding: 0 10px;
  margin: 5px;
}

.plotline-active {
  background-color: #7b8a39;
  color: #ffffff;
}

button.accordion-button {
  padding: 5px 15px;
}

.datum-toggle {
  padding: 0px 5px;
  font-size: medium;
}

.collect-trip-options {
  display: flex;
  justify-content: space-between;
}

.group-collect {
  width: 45%;
  border: 1px solid rgb(180, 180, 180);
  padding: 20px;
}

.individual-collect {
  width: 52%;
  border: 1px solid #b4b4b4;
  padding: 20px;
}

@media (max-width: 850px) {
  .collect-trip-options {
    flex-direction: column;
  }
  .individual-collect {
    width: 100%;
    margin-top: 50px;
  }
  .group-collect {
    width: 100%;
  }
}
