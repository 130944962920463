//@import '../bootstrap/bootstrap.scss';

// COLORS
// GMRI
$gmri-light-green: #b1b900;
$gmri-blue: #005d8e;
$gmri-dark-grey: #6b6e70;
$gmri-light-grey: #e9e9e9;
$gmri-orange: #faa046;
$gmri-yellow: #eaca00;
$gmri-red: #ea4f12;
// GMRI ACCENT
$gmri-accent-green: #00736d;
$gmri-accent-blue: #061f2b;
$gmri-light-blue: #55cae3;

// VITAL SIGNS
$citizen-science: #7b8a39;

// LABVENTURE
$labventure-blue: #0098b8;
$labventure-light-blue: #55cae3;

// BOOTSTRAP ASSIGNMENT
$primary: $gmri-blue;
$secondary: $gmri-light-green;
$success: $gmri-accent-green;
$info: $gmri-orange;
$warning: $gmri-yellow;
$danger: $gmri-red;
$light: $gmri-light-grey;
$dark: $gmri-dark-grey;

$yiq-text-dark: $gmri-blue;
$yiq-text-light: white;

// FONTS
// This CSS resource incorporates links to font software which is
// the valuable copyrighted property of Monotype Imaging and/or
// its suppliers. You may not attempt to copy, install, redistribute,
// convert, modify or reverse engineer this font software. Please
// contact Monotype Imaging with any questions regarding Web Fonts:
// http: //www.fonts.com
// Fonts.com tracking code.
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=9530df97-5167-492b-ab55-9f1555fe1a0b");
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: lighter;
  src: url("../fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix");
  src: url("../fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix") format("eot"),
    url("../fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),
    url("../fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff"),
    url("../fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: oblique;
  font-weight: lighter;
  src: url("../fonts/63a166cb-be60-435c-a056-75172e43efa5.eot?#iefix");
  src: url("../fonts/63a166cb-be60-435c-a056-75172e43efa5.eot?#iefix") format("eot"),
    url("../fonts/a59168c1-917d-4de9-a244-0316c057c357.woff2") format("woff2"),
    url("../fonts/6dc0e7d8-9284-44e1-8f05-984a41daa3a4.woff") format("woff"),
    url("../fonts/2315df7a-8bc2-433d-bf0a-162fc0063de0.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix");
  src: url("../fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix") format("eot"),
    url("../fonts/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),
    url("../fonts/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"),
    url("../fonts/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: oblique;
  font-weight: 200;
  src: url("../fonts/d8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix");
  src: url("../fonts/d8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix") format("eot"),
    url("../fonts/476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2") format("woff2"),
    url("../fonts/f1ebae2b-5296-4244-8771-5f40e60a564a.woff") format("woff"),
    url("../fonts/04d1bf6c-070d-4b7e-9498-6051c9f0c349.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/57bf7902-79ee-4b31-a327-1bbf59a3d155.eot?#iefix");
  src: url("../fonts/57bf7902-79ee-4b31-a327-1bbf59a3d155.eot?#iefix") format("eot"),
    url("../fonts/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2") format("woff2"),
    url("../fonts/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff") format("woff"),
    url("../fonts/9bdf0737-f98c-477a-9365-ffc41b9d1285.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: oblique;
  font-weight: 300;
  src: url("../fonts/1e9b2738-9316-4b0c-97c0-5423b69ea147.eot?#iefix");
  src: url("../fonts/1e9b2738-9316-4b0c-97c0-5423b69ea147.eot?#iefix") format("eot"),
    url("../fonts/1a7173fa-062b-49ad-9915-bc57d3bfc1f5.woff2") format("woff2"),
    url("../fonts/cdda031e-26e9-4269-83d1-5a218caa10db.woff") format("woff"),
    url("../fonts/6d1ce413-19e7-4b43-b328-6cdedc58b35a.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix");
  src: url("../fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix") format("eot"),
    url("../fonts/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"),
    url("../fonts/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff"),
    url("../fonts/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: oblique;
  font-weight: 400;
  src: url("../fonts/cebcf56a-ea7e-4f5c-8110-a7ee367c1f91.eot?#iefix");
  src: url("../fonts/cebcf56a-ea7e-4f5c-8110-a7ee367c1f91.eot?#iefix") format("eot"),
    url("../fonts/deb5e718-7abb-4df3-9365-edfa95317090.woff2") format("woff2"),
    url("../fonts/04801919-17ee-4c6b-8b17-eb1965cb3ed6.woff") format("woff"),
    url("../fonts/cee053ec-4b41-4506-a1fe-796261690610.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix");
  src: url("../fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"),
    url("../fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),
    url("../fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"),
    url("../fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: oblique;
  font-weight: 700;
  src: url("../fonts/e6b412d9-d1ed-4b17-bb93-a6911df8640d.eot?#iefix");
  src: url("../fonts/e6b412d9-d1ed-4b17-bb93-a6911df8640d.eot?#iefix") format("eot"),
    url("../fonts/3c210c80-960f-4684-850b-25390b4d08af.woff2") format("woff2"),
    url("../fonts/cb5c71ad-e582-4d00-929c-67fbfaeb1c27.woff") format("woff"),
    url("../fonts/9ced8e96-4602-4507-8c20-4ff381949a9a.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: bolder;
  src: url("../fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix");
  src: url("../fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix") format("eot"),
    url("../fonts/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"),
    url("../fonts/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff"),
    url("../fonts/b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf") format("truetype");
}
@font-face {
  font-family: "Avenir";
  font-style: oblique;
  font-weight: bolder;
  src: url("../fonts/4030caeb-d266-4d36-ac58-a3bb7a5b1bfa.eot?#iefix");
  src: url("../fonts/4030caeb-d266-4d36-ac58-a3bb7a5b1bfa.eot?#iefix") format("eot"),
    url("../fonts/a2477e08-09d9-4d4b-97a9-23a1e22cb44c.woff2") format("woff2"),
    url("../fonts/19d12bba-92b1-43ad-9bab-cd36a4195c2a.woff") format("woff"),
    url("../fonts/5ba5a010-7470-4d9d-8a49-2920dc1be1f8.ttf") format("truetype");
}
@font-face {
  font-family: "ITC Berkeley Oldstyle";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/930df63a-3fbe-4287-803a-2cfe85906a6c.eot?#iefix");
  src: url("../fonts/930df63a-3fbe-4287-803a-2cfe85906a6c.eot?#iefix") format("eot"),
    url("../fonts/5c91c85e-3618-4279-87b2-40140b707262.woff2") format("woff2"),
    url("../fonts/b3ee5ed4-4a49-490b-ba6f-73b52479494a.woff") format("woff"),
    url("../fonts/5212d1be-f1d8-4aae-bd04-593f280c1e2e.ttf") format("truetype");
}

@font-face {
  font-family: "ITC Berkeley Oldstyle";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/d00b95f2-f29c-4e5b-b15c-6da35890e8fb.eot?#iefix");
  src: url("../fonts/d00b95f2-f29c-4e5b-b15c-6da35890e8fb.eot?#iefix") format("eot"),
    url("../fonts/e2b3ea0f-5b14-4694-9fce-2d9558ff55b4.woff2") format("woff2"),
    url("../fonts/546f63af-d80a-46e7-a662-5c8c3eaced3f.woff") format("woff"),
    url("../fonts/a736620d-674e-43d4-b5d1-7fa1e18f6d5a.ttf") format("truetype");
}

@font-face {
  font-family: "ITC Berkeley Oldstyle";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/dc2f61e0-5d45-4088-86c6-da5b5be823f8.eot?#iefix");
  src: url("../fonts/dc2f61e0-5d45-4088-86c6-da5b5be823f8.eot?#iefix") format("eot"),
    url("../fonts/37196a02-68f7-48ac-97e7-5613ffe46c17.woff2") format("woff2"),
    url("../fonts/c55afb96-b7a1-4c0c-92e2-74528b434afd.woff") format("woff"),
    url("../fonts/b432e5ea-eccb-41cc-b639-22913a755094.ttf") format("truetype");
}

@font-face {
  font-family: "ITC Berkeley Oldstyle";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/978a1eeb-9f29-4be5-b6ce-58eb906522e8.eot?#iefix");
  src: url("../fonts/978a1eeb-9f29-4be5-b6ce-58eb906522e8.eot?#iefix") format("eot"),
    url("../fonts/e318ceb4-51a9-47b7-8c55-4ceaaf717336.woff2") format("woff2"),
    url("../fonts/13f4b4db-66e8-403b-9ed2-4e38cabbf768.woff") format("woff"),
    url("../fonts/7d10b00e-928a-457b-ba6d-042fa00bfa4d.ttf") format("truetype");
}

@font-face {
  font-family: "ITC Berkeley Oldstyle";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/9d79632a-ebab-4d8a-b13c-3baf21aa6517.eot?#iefix");
  src: url("../fonts/9d79632a-ebab-4d8a-b13c-3baf21aa6517.eot?#iefix") format("eot"),
    url("../fonts/8fc78d7f-3029-4988-aee4-01fbcf18f17a.woff2") format("woff2"),
    url("../fonts/861098c2-3065-4e34-b7a5-b9a66b10796a.woff") format("woff"),
    url("../fonts/6be811b9-5983-4db4-ab4a-aa0283ed0041.ttf") format("truetype");
}

@font-face {
  font-family: "ITC Berkeley Oldstyle";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/e0c1c949-f559-4c3d-b258-dc45397d3c53.eot?#iefix");
  src: url("../fonts/e0c1c949-f559-4c3d-b258-dc45397d3c53.eot?#iefix") format("eot"),
    url("../fonts/a83f1dfb-702f-43bf-8e61-110f590222c6.woff2") format("woff2"),
    url("../fonts/6bbcd888-18c9-4b1a-8162-c62316d199d9.woff") format("woff"),
    url("../fonts/229582a5-c250-42e4-a9d4-0947096bfb31.ttf") format("truetype");
}

@font-face {
  font-family: "ITC Berkeley Oldstyle";
  font-style: normal;
  font-weight: bolder;
  src: url("../fonts/de1ce084-e34e-4c13-8c7e-a68eb8509ad3.eot?#iefix");
  src: url("../fonts/de1ce084-e34e-4c13-8c7e-a68eb8509ad3.eot?#iefix") format("eot"),
    url("../fonts/36f55f82-f741-4829-9de1-d0589bbdb0ac.woff2") format("woff2"),
    url("../fonts/744e94af-4b5a-45db-88e4-4595ed9d4f04.woff") format("woff"),
    url("../fonts/a5d1f3cb-543c-470d-8667-f39ba5ef1157.ttf") format("truetype");
}

@font-face {
  font-family: "ITC Berkeley Oldstyle";
  font-style: italic;
  font-weight: bolder;
  src: url("../fonts/6f94f9f4-78c0-4886-8d0e-6fb0d1395ac4.eot?#iefix");
  src: url("../fonts/6f94f9f4-78c0-4886-8d0e-6fb0d1395ac4.eot?#iefix") format("eot"),
    url("../fonts/23709d51-ab45-4937-87d4-ef8ccc57658e.woff2") format("woff2"),
    url("../fonts/27fc644b-616c-41df-9453-9d9442e34275.woff") format("woff"),
    url("../fonts/25745818-f86d-4464-b24c-deeb24391484.ttf") format("truetype");
}

$primary-fonts: "Avenir", sans-serif;
$font-size-base: 1.125rem;
$line-height-base: 1.67;
$body-color: $gmri-dark-grey;

$font-family-sans-serif: $primary-fonts;
$font-family-serif: "ITC Berkeley Oldstyle", serif;

$font-weight-base: 300;

$h1-font-size: $font-size-base * 3.11;
$h2-font-size: $font-size-base * 2.56;
$h3-font-size: $font-size-base * 1.56;
$h4-font-size: $font-size-base * 1.35;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-font-family: $primary-fonts;
$headings-font-weight: 700;
$headings-line-height: 1.3;
$headings-color: $gmri-dark-grey;
